
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header({ handleMenuPopupOpen }) {
    const location = useLocation()
    return (
        <header className="header">
            <Link to='/' className="header__logo">
                <svg
                    width={75}
                    height={82}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="header__logo-svg"
                    viewBox="0 0 75 82"

                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M31.506 8.914a1.366 1.366 0 00-1.244-1.142 1.362 1.362 0 00-1.396.948L25.07 20.577h-4.896c-.753 0-1.362.614-1.362 1.372 0 .758.61 1.372 1.362 1.372h5.888c.592 0 1.115-.384 1.297-.95l2.385-7.45 3.34 20.063a1.366 1.366 0 001.267 1.143 1.363 1.363 0 001.386-.992L40.15 19.62l2.324 8.196a1.359 1.359 0 002.485.317l2.797-4.81h2.118c.593 2.111 2.52 3.659 4.806 3.659 2.759 0 4.995-2.253 4.995-5.032s-2.236-5.032-4.995-5.032c-2.286 0-4.213 1.548-4.806 3.66h-2.898c-.484 0-.931.258-1.176.678l-1.582 2.721-2.757-9.723a1.364 1.364 0 00-1.31-.995c-.607 0-1.142.405-1.31.994l-4.066 14.3-3.27-19.638zM52.41 21.949a2.279 2.279 0 012.27-2.287 2.279 2.279 0 012.27 2.287 2.279 2.279 0 01-2.27 2.287 2.279 2.279 0 01-2.27-2.287z"
                        fill="#fff"
                        className={`${['/', '/ai', '/doctors'].indexOf(location.pathname) < 0 ? "header__logo-svg_invert" : '' }`}
                    />
                    <path
                        d="M6.681 54.13h11.312L6.031 74.433H21.96v-2.62H10.647L22.61 51.51H6.681v2.62zM28.035 67.557h12.027l-.975-2.62H29.01l-.975 2.62zm5.949-10.873l3.9 9.235.26.655 3.316 7.86h3.088l-10.564-24.07-10.565 24.07h3.088l3.381-8.056.26-.59 3.836-9.104zM50.47 58.944l7.41 13.361 7.412-13.361 1.69 15.49h2.763l-2.763-24.07-9.101 16.603-9.102-16.604-2.763 24.071h2.763l1.69-15.49z"
                        fill="#fff"
                        className={`${['/', '/ai', '/doctors'].indexOf(location.pathname) < 0 ? "header__logo-svg_invert" : '' }`}
                    />
                </svg>
            </Link>
            <svg
                width={40}
                height={40}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__menu"
                onClick={() => {
                    handleMenuPopupOpen()
                }}
                viewBox="0 0 40 40"
            >
                <rect x={6} y={13} width={28} height={3} rx={1.5} fill="#fff" 
                 className={`${['/', '/ai', '/doctors'].indexOf(location.pathname) < 0 ? "header__logo-svg_invert" : '' }`}
                 />
                <rect x={6} y={24} width={28} height={3} rx={1.5} fill="#fff"
                 className={`${['/', '/ai', '/doctors'].indexOf(location.pathname) < 0 ? "header__logo-svg_invert" : '' }`}
                  />
            </svg>
        </header>
    );
}

export default Header;
