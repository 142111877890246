import './Description.css';
import lines from '../../../assets/images/lines.png'
import lines2 from '../../../assets/images/lines2.png'
import { useEffect, useRef, useState } from 'react';
import { NEXT, PREV } from '../../../assets/utils/constants';
import carusel1 from '../../../assets/images/carusel1.png'
import carusel2 from '../../../assets/images/carusel2.png'
import carusel3 from '../../../assets/images/carusel3.png'
import carusel4 from '../../../assets/images/carusel4.png'
import carusel5 from '../../../assets/images/carusel5.png'


const steps = [
    {
        title: 'Red flags',
        img: carusel5,
        text: "Our app can monitor critical values in patients' conditions and notify both the patients and doctors of any sudden changes",
    },
    {
        title: 'Current state',
        img: carusel1,
        text: 'Our app can show whether the patient is improving or worsening with their current treatment',
    },
    {
        title: 'Forecasting',
        img: carusel2,
        text: 'Our app can predict the condition of a particular patient in their current medical course',
    },
    {
        title: 'Changes in treatment',
        img: carusel3,
        text: 'Our app can suggest which medicine(s) may be the most effective for an individual patient',
    },
    {
        title: 'Effectiveness',
        img: carusel4,
        text: 'Our app can help to understand the effectiveness of a medicine for different groups of patients',
    },
]

function Description() {
    const [step, setStep] = useState(0)
    const [direction, setDirection] = useState(NEXT);
    const [isSliding, setSliding] = useState(false);

    const timer = useRef(null);

    useEffect(() => {
        timer.current = setTimeout(() => {
            if (step < steps.length - 1) {
                setStep(step + 1);
            } else {
                setStep(0);
            }

            setOptions(NEXT);
            setTimeout(() => {
                setSliding(false);
            }, 50);
        }, 10000);
    }, [steps, step]);

    function setOptions(swipe) {
        setDirection(swipe);
        setSliding(true);
        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    function getOrder({ index, step, numItems }) {
        return index - step < 0
            ? numItems - Math.abs(index - step)
            : index - step;
    }

    function handleSlide(swipe) {
        if (swipe === NEXT) {
            setStep(step === steps.length - 1 ? 0 : step + 1);
        } else if (swipe === PREV) {
            setStep(step === 0 ? steps.length - 1 : step - 1);
        }

        setOptions(swipe);
        clearTimeout(timer.current);

        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    return (
        <div className="description">
            <p className="description__title">The future of medicine empowered by AI</p>
            <p className="description__subtitle">We combine multiple data sources to train our predictive model and provide the most up-to-date recommendations</p>
            <div className="description__cards description__cards_desktop">
                <div className="description__card description__card_1">
                    <p className="description__card-title">Mark</p>
                    <p className="description__card-value">Daily questionnaires</p>
                    <p className="description__card-value">Intake history</p>
                    <p className="description__card-value">Other patients' data</p>
                    <p className="description__card-value">Anamnesis</p>
                </div>
                <div className="description__card description__card_2">
                    <p className="description__card-title">John</p>
                    <p className="description__card-value">Daily questionnaires</p>
                    <p className="description__card-value">Intake history</p>
                    <p className="description__card-value">Other patients' data</p>
                    <p className="description__card-value">Anamnesis</p>
                </div>
                <div className="description__card description__card_3">
                    <p className="description__card-title">Open data sources</p>
                    <p className="description__card-value">Global stats</p>
                    <p className="description__card-value">Researches</p>
                    <p className="description__card-value">Medical Papers</p>
                </div>
            </div>
            <div className='description__lines-box description__lines_desktop'>
                <svg
                    width={1200}
                    height={169}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className='description__lines'
                >
                    <path
                        stroke="#AEB7B0"
                        strokeWidth={1.2}
                        strokeDasharray="8 8"
                        d="M601.1.002l-.5 167.999M1200 .6H0"
                    />
                </svg>
            </div>


            {/* <img src={lines} className='description__lines description__lines_desktop'></img> */}
            <div className="description__cards description__cards_mobile">
                <div className="description__card ">
                    <p className="description__card-title">Patient</p>
                    <p className="description__card-value">Daily questionnaires</p>
                    <p className="description__card-value">Intake history</p>
                    <p className="description__card-value">Other patients' data</p>
                    <p className="description__card-value">Anamnesis</p>
                </div>

                <div className="description__card">
                    <p className="description__card-title">Open data sources</p>
                    <p className="description__card-value">Global stats</p>
                    <p className="description__card-value">Researches</p>
                    <p className="description__card-value">Medical Papers</p>
                </div>
            </div>
            <div className='description__lines-box description__lines_mobile'>
                <svg
                    width={1200}
                    height={169}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className='description__lines'
                >
                    <path
                        stroke="#AEB7B0"
                        strokeWidth={1.2}
                        strokeDasharray="8 8"
                        d="M601.1.002l-.5 167.999M1200 .6H0"
                    />
                </svg>
            </div>

            <div className='description__engine'>
                <div className='description__engine-bg'></div>
                <p className='description__engine-name'>caba.<br />med_engine</p>
            </div>
            <svg
                width={2}
                height={148}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"

            >
                <path
                    stroke="#AEB7B0"
                    strokeWidth={1.2}
                    strokeDasharray="8 8"
                    d="M.6 0v148"
                />
            </svg>

            <div className='description__result'>
                <div className='description__result-content'>
                    <svg
                        width={44}
                        height={45}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='description__result-arrow description__result-arrow_left'
                        onClick={() => {
                            handleSlide(PREV)
                        }}
                        viewBox="0 0 44 45"
                    >
                        <path
                            d="M27.347 30.254l-7.932-7.756 7.932-7.752a2.17 2.17 0 000-3.104 2.29 2.29 0 00-3.18 0l-9.514 9.306a2.169 2.169 0 000 3.102l9.514 9.308c.88.857 2.3.855 3.18 0 .87-.86.87-2.247 0-3.104z"
                            fill="#2D6956"
                        />
                    </svg>
                    <svg
                        width={44}
                        height={45}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='description__result-arrow description__result-arrow_right'
                        onClick={() => {
                            handleSlide(NEXT)
                        }}
                        viewBox="0 0 44 45"
                    >

                        <path
                            d="M27.347 30.254l-7.932-7.756 7.932-7.752a2.17 2.17 0 000-3.104 2.29 2.29 0 00-3.18 0l-9.514 9.306a2.169 2.169 0 000 3.102l9.514 9.308c.88.857 2.3.855 3.18 0 .87-.86.87-2.247 0-3.104z"
                            fill="#2D6956"
                        />
                    </svg>
                    <div className="description__carousel-wrapper">
                        <div className={`description__carousel 
      ${isSliding ? "description__carousel_sliding" : ""
                            } ${isSliding && direction === PREV
                                ? "description__carousel_type_prev"
                                : ""
                            } ${isSliding && direction === NEXT
                                ? "description__carousel_type_next"
                                : ""
                            }`}
                            dir={direction}
                        // sliding={isSliding ? isSliding : undefined}
                        >
                            {steps.map((item, i) => (
                                <div className="description__carousel-card"
                                    style={{
                                        order: getOrder({
                                            index: i,
                                            step: step,
                                            numItems: steps.length
                                        })
                                    }}
                                    key={`slide-${i}`}
                                >
                                    <p className="description__carousel-card-title">{item.title}</p>
                                    <img className="description__carousel-card-img" src={item.img} alt=''></img>
                                    <p className="description__carousel-card-text">{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='description__result-steps'>
                    {steps.map((item, i) => (
                        <div className={`description__result-step ${step === i? 'description__result-step_active' : ''}`}>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default Description;
