
import { Link } from 'react-router-dom';
import './NotFond.css';


function NotFond() {
    return (

        <div className='not-fond'>
            <p className='not-fond__title'>404</p>
            <p className='not-fond__subtitle'>This page was not found</p>
            <Link  className='not-fond__link' to='/'>Main</Link>
        </div>
    );
}

export default NotFond;
