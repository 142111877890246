import { useEffect, useRef, useState } from 'react';
import { NEXT, PREV } from '../../../assets/utils/constants';
import './Data.css';


const steps = [


    {
        name: "Worse",
    },
    {
        name: "Better",
    },

    {
        name: "Neutral",
    },

]

function Data() {

    const [step, setStep] = useState(0)
    const [direction, setDirection] = useState(NEXT);
    const [isSliding, setSliding] = useState(false);

    const timer = useRef(null);

    useEffect(() => {
        timer.current = setTimeout(() => {
            if (step < steps.length - 1) {
                setStep(step + 1);
            } else {
                setStep(0);
            }

            setOptions(NEXT);
            // setTimeout(() => {
            //     setSliding(false);
            // }, 1000);
        }, 2000);
    }, [steps, step]);

    function setOptions(swipe) {
        console.log(swipe)
        setDirection(swipe);
        setSliding(true);
        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    function getOrder({ index, step, numItems }) {
        return index - step < 0
            ? numItems - Math.abs(index - step)
            : index - step;
    }

    function handleSlide(swipe) {
        if (swipe === NEXT) {
            setStep(step === steps.length - 1 ? 0 : step + 1);
        } else if (swipe === PREV) {
            setStep(step === 0 ? steps.length - 1 : step - 1);
        }

        setOptions(swipe);
        clearTimeout(timer.current);

        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    return (

        <div className='doctors-data'>
            <p className='doctors-data__title'>The data collected from the patients is used to form a score of their well-being, which is visible to the doctor on their dashboard</p>
            <div className='doctors-data__graph-box'>
                <div className='doctors-data__graph-container'>
                    <div className='doctors-data__graph-names'>
                        <div className={`doctors-data__graph-names-carousel 
      ${isSliding ? "doctors-data__graph-names-carousel_sliding" : ""
                            }  ${isSliding && direction === NEXT
                                ? "doctors-data__graph-names-carousel_type_next"
                                : ""
                            }`}
                            dir={direction}
                        // sliding={isSliding ? isSliding : undefined}
                        >
                            {steps.map((item, i) => (
                                <div className='doctors-data__graph-name'
                                    style={{
                                        order: getOrder({
                                            index: i,
                                            step: step,
                                            numItems: steps.length
                                        })
                                    }}
                                    key={`slide-${i}`}
                                >
                                    {item.name === 'Better' ?
                                        <svg
                                            width={36}
                                            height={37}
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className='doctors-data__graph-name-icon'
                                        >
                                            <g clipPath="url(#prefix__clip0_3654_2862)">
                                                <path
                                                    d="M18 10.625L5.625 22.467l2.908 2.783L18 16.21l9.467 9.04 2.908-2.783L18 10.625z"
                                                    fill="#2D6956"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="prefix__clip0_3654_2862">
                                                    <path fill="#fff" transform="translate(0 .5)" d="M0 0h36v36H0z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        : <></>}
                                    {item.name === 'Neutral' ?
                                        <svg
                                            width={36}
                                            height={37}
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className='doctors-data__graph-name-icon'
                                        >
                                            <g clipPath="url(#prefix__clip0_3386_25462)">
                                                <path d="M17.932 17h-6.885v1.998h13.77V17h-6.885z" fill="#FFC531" />
                                            </g>
                                            <defs>
                                                <clipPath id="prefix__clip0_3386_25462">
                                                    <path fill="#fff" transform="translate(0 .5)" d="M0 0h36v36H0z" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        : <></>}
                                    {item.name === 'Worse' ?
                                        <svg
                                            width={36}
                                            height={37}
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className='doctors-data__graph-name-icon'
                                        >
                                            <g clipPath="url(#prefix__clip0_3386_25484)">
                                                <path
                                                    d="M18 24.5l9-8.502L24.885 14 18 20.49 11.115 14 9 15.998l9 8.502z"
                                                    fill="#AE1500"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="prefix__clip0_3386_25484">
                                                    <path
                                                        fill="#fff"
                                                        transform="rotate(-180 18 18.25)"
                                                        d="M0 0h36v36H0z"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        : <></>}


                                    <p className='doctors-data__graph-name-text'>{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <svg
                        width={542}
                        height={196}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`doctors-data__graph-svg ${step === 1 ? 'doctors-data__graph-svg_active' : ''}`}
                        viewBox='0 0 542 196'
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M95.58 18.303C62.39-3.88 18.833 6.165 0 13.729V196h542V37.37C493.979 37.37 457.664 0 417.183 0c-58.473 4.576-77.188 41.375-131.565 54.148-54.376 12.773-58.066-35.845-96.705-35.845-26.208 0-60.141 22.184-93.332 0z"
                            fill="url(#prefix__paint0_linear_3386_25471)"
                        />
                        <defs>
                            <linearGradient
                                id="prefix__paint0_linear_3386_25471"
                                x1={271}
                                y1={-125.837}
                                x2={271}
                                y2={196}
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#ECFF91" />
                                <stop offset={1} stopColor="#FFB470" stopOpacity={0.89} />
                            </linearGradient>
                        </defs>
                    </svg>
                    <svg
                        width={542}
                        height={227}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`doctors-data__graph-svg ${step === 0 ? 'doctors-data__graph-svg_active' : ''}`}
                        viewBox='0 0 542 227'
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M100 201.542c-33.19-15.647-81.168 12.699-100 18.033V227h542V0c-48.021 0-98.278 22.522-117.404 33.783-21.48 12.842-75.219 98.861-129.596 107.87-54.377 9.009-88.608 33.893-107.5 46.099-15.595 10.076-54.309 29.437-87.5 13.79z"
                            fill="url(#prefix__paint0_linear_3654_2876)"
                        />
                        <defs>
                            <linearGradient
                                id="prefix__paint0_linear_3654_2876"
                                x1={271}
                                y1={0}
                                x2={271}
                                y2={227}
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#F1FFAD" />
                                <stop offset={1} stopColor="#2D6956" stopOpacity={0.56} />
                            </linearGradient>
                        </defs>
                    </svg>
                    <svg
                        width={542}
                        height={210}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox='0 0 542 210'
                        className={`doctors-data__graph-svg ${step === 2 ? 'doctors-data__graph-svg_active' : ''}`}
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M111 90.933C77.81 75.045 18.832-5.154 0 .262V210h542v-29.183c-48.021 0-65.272-24.739-112.5-28.016-38.961-2.703-66.623-18.486-121-9.338-54.377 9.147-69.641-67.28-104.5-78.75-21.698-7.14-59.809 42.108-93 26.22z"
                            fill="url(#prefix__paint0_linear_3386_25488)"
                        />
                        <defs>
                            <linearGradient
                                id="prefix__paint0_linear_3386_25488"
                                x1={271}
                                y1={-20.493}
                                x2={271}
                                y2={210}
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#FFDEAD" />
                                <stop offset={1} stopColor="#D00000" stopOpacity={0.56} />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <p className='doctors-data__graph-hint'>The result of a change in score determines how much the patient is improving and whether changes to their treatment plan should be applied</p>
            </div>
        </div>
    );
}

export default Data;
