
import './Meet.css';


function Meet() {
    return (
        <div className="meet">
            <p className='meet__heading'>Made for doctors, designed for people</p>
            <p className='meet__text'>At ZAM, we aim to break down boundaries between all stakeholders for a better future of medicine and health</p>
            <div className='meet__cards'>
                <div className='meet__card'>
                    <svg
                        width={32}
                        height={32}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='meet__card-icon'
                    >
                        <path
                            d="M13 20a5 5 0 11-10 0 5 5 0 0110 0zM3 20h10M23 8a5 5 0 11-10 0 5 5 0 0110 0M13 8h10M28 25a5 5 0 11-10 0 5 5 0 0110 0zM18 25h10"
                            stroke="#191C1B"
                            strokeMiterlimit={10}
                        />
                    </svg>
                    <p className='meet__card-text'>We aim to create a better experience for taking medication and following medical schedule, while accurately reflecting the effects of drugs on the body</p>
                </div>
                <div className='meet__card'>
                    
                    <svg
                        width={32}
                        height={32}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='meet__card-icon'
                    >
                        <g clipPath="url(#prefix__clip0_3570_25601)">
                            <path
                                d="M9.407 13.43v13.518c0 2.786 2.232 5.052 4.976 5.052 2.745 0 4.977-2.266 4.977-5.052v-10.82a2.88 2.88 0 012.871-2.881 2.88 2.88 0 012.872 2.882v4.422a3.172 3.172 0 00-2.359 3.077c0 1.751 1.404 3.176 3.128 3.176 1.725 0 3.128-1.425 3.128-3.176 0-1.45-.962-2.675-2.273-3.053v-4.446c0-2.5-2.017-4.532-4.496-4.532-2.479 0-4.496 2.033-4.496 4.532v10.819c0 1.876-1.503 3.402-3.352 3.402-1.848 0-3.351-1.526-3.351-3.402V13.429c3.599-.411 6.406-3.52 6.406-7.281V.825A.819.819 0 0016.626 0a.819.819 0 00-.813.825v5.322c0 3.132-2.51 5.68-5.594 5.68-3.085 0-5.594-2.548-5.594-5.68V.825A.819.819 0 003.812 0 .819.819 0 003 .825v5.322c0 3.763 2.807 6.87 6.407 7.282zm17.968 10.2c0 .84-.674 1.525-1.502 1.525a1.516 1.516 0 01-1.503-1.526c0-.84.674-1.525 1.503-1.525.828 0 1.502.684 1.502 1.525z"
                                fill="#191C1B"
                            />
                        </g>
                        <defs>
                            <clipPath id="prefix__clip0_3570_25601">
                                <path fill="#fff" d="M0 0h32v32H0z" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p className='meet__card-text'>We aim to be your personal medical resource to whom you can turn with any question at any time, and our advice will help improve your well-being</p>
                </div>
            </div>
        </div>
    );
}

export default Meet;
