
import './Banner.css';


function Banner() {
    return (
        <div className="about-banner">
           <p className='about-banner__title'>We believe that the future of medicine depends on the quality of communication between doctors, patients, and pharmaceutical manufacturers</p>
            
        </div>
    );
}

export default Banner;
