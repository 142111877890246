
import { useEffect, useRef, useState } from 'react';
import { NEXT, PREV } from '../../../assets/utils/constants';
import './Reviews.css';

import reviewer from '../../../assets/images/reviewer.png'

const steps = [
   
    {
        name: "John O'Brien",
        department: "Clinic",
        img: reviewer,
        text: "Doctor's review under editing",
    },
    {
        name: "John O'Brien",
        department: "Clinic",
        img: reviewer,
        text: "Doctor's review under editing",
    },
   
]

function Reviews() {

    const [step, setStep] = useState(0)
    const [direction, setDirection] = useState(NEXT);
    const [isSliding, setSliding] = useState(false);

    const timer = useRef(null);

    useEffect(() => {
        timer.current = setTimeout(() => {
            if (step < steps.length - 1) {
                setStep(step + 1);
            } else {
                setStep(0);
            }

            setOptions(NEXT);
            setTimeout(() => {
                setSliding(false);
            }, 50);
        }, 10000);
    }, [steps, step]);

    function setOptions(swipe) {
        setDirection(swipe);
        setSliding(true);
        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    function getOrder({ index, step, numItems }) {
        return index - step < 0
            ? numItems - Math.abs(index - step)
            : index - step;
    }

    function handleSlide(swipe) {
        if (swipe === NEXT) {
            setStep(step === steps.length - 1 ? 0 : step + 1);
        } else if (swipe === PREV) {
            setStep(step === 0 ? steps.length - 1 : step - 1);
        }

        setOptions(swipe);
        clearTimeout(timer.current);

        setTimeout(() => {
            setSliding(false);
        }, 50);
    }

    return (

        <div className='reviews'>
            <p className='reviews__title'>We have proven that the help of AI improves the quality of treatment and the speed of recovery for patients in practice</p>
            <div className='reviews__result'>
                <div className='reviews__result-content'>
                    <svg
                        width={44}
                        height={45}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='reviews__result-arrow reviews__result-arrow_left'
                        onClick={() => {
                            handleSlide(PREV)
                        }}
                        viewBox="0 0 44 45"
                    >
                        <path
                            d="M27.347 30.254l-7.932-7.756 7.932-7.752a2.17 2.17 0 000-3.104 2.29 2.29 0 00-3.18 0l-9.514 9.306a2.169 2.169 0 000 3.102l9.514 9.308c.88.857 2.3.855 3.18 0 .87-.86.87-2.247 0-3.104z"
                            fill="#2D6956"
                        />
                    </svg>
                    <svg
                        width={44}
                        height={45}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className='reviews__result-arrow reviews__result-arrow_right'
                        onClick={() => {
                            handleSlide(NEXT)
                        }}
                        viewBox="0 0 44 45"
                    >

                        <path
                            d="M27.347 30.254l-7.932-7.756 7.932-7.752a2.17 2.17 0 000-3.104 2.29 2.29 0 00-3.18 0l-9.514 9.306a2.169 2.169 0 000 3.102l9.514 9.308c.88.857 2.3.855 3.18 0 .87-.86.87-2.247 0-3.104z"
                            fill="#2D6956"
                        />
                    </svg>
                    <div className="reviews__carousel-wrapper">
                        <div className={`reviews__carousel 
      ${isSliding ? "reviews__carousel_sliding" : ""
                            } ${isSliding && direction === PREV
                                ? "reviews__carousel_type_prev"
                                : ""
                            } ${isSliding && direction === NEXT
                                ? "reviews__carousel_type_next"
                                : ""
                            }`}
                            dir={direction}
                        // sliding={isSliding ? isSliding : undefined}
                        >
                            {steps.map((item, i) => (
                                <div className="reviews__carousel-card"
                                    style={{
                                        order: getOrder({
                                            index: i,
                                            step: step,
                                            numItems: steps.length
                                        })
                                    }}
                                    key={`slide-${i}`}
                                >
                                    <p className="reviews__carousel-card-text">{item.text}</p>
                                    <img className="reviews__carousel-card-img" src={item.img} alt=''></img>
                                    <p className="reviews__carousel-card-name">{item.name}</p>
                                    <p className="reviews__carousel-card-department">{item.department}</p>
                                    {/* <p className="reviews__carousel-card-title">{item.title}</p>
                                    <img className="reviews__carousel-card-img" src={item.img} alt=''></img>
                                    <p className="reviews__carousel-card-text">{item.text}</p> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='reviews__result-steps'>
                    {steps.map((item, i) => (
                        <div className={`reviews__result-step ${step === i ? 'reviews__result-step_active' : ''}`}>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default Reviews;
