import { useState } from 'react';
import validator from 'validator';
import mainApi from '../../assets/api/mainApi';

import './Newsletter.css';

function Newsletter() {
    const [emailValue, setEmailValue] = useState('')
    const [emailValidity, setEmailValidity] = useState({})

    function handleEmailChange(evt) {
        const input = evt.target;
        const name = input.name;
        let value = input.value;

        setEmailValue(value);
        if (!value) {
            setEmailValidity({
                errorMessage: 's',
                validState: false,
            });
        } else {
            if (value.length > 5) {
                if (validator.isEmail(value)) {
                    setEmailValidity({
                        errorMessage: "",
                        validState: true,
                    });

                } else {

                    setEmailValidity({
                        errorMessage: 'ds',
                        validState: false,
                    });
                }
            } else {

                setEmailValidity({
                    errorMessage: "",
                    validState: false,
                });
            }
        }

    }

    function handleSubmit() {
        mainApi.newsletterSubscribe({ email: emailValue })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setEmailValidity({})
                setEmailValue('')
            })
    }

    return (
        <div className="newsletter">
            <p className="newsletter__title">Don't miss out on updates</p>
            <p className="newsletter__text">We regularly send updates and allow our users to have an impact in the future course of our development</p>
            <div className="newsletter__input-box">
                <input className={`newsletter__input `} placeholder={'Email'} name="e-.m-/a-/i-/l" type="text" value={emailValue} onChange={handleEmailChange} minLength="0" maxLength="100"></input>
                <button type='button' className={`newsletter__input-submit ${emailValidity.validState ? 'newsletter__input-submit_valid' : ''}`} onClick={handleSubmit}>
                    Send
                </button>
            </div>
        </div>
    );
}

export default Newsletter;
