
import './Road.css';


function Road() {
    return (

        <div className='road'>
            <p className='road__title'>Our development plan includes integrating the newest and best practices to enhance your experience with the app and make it your best companion</p>
            <div className='road__cards'>
                <div className='road__cards-lines'>
                    <div className='road__cards-lines-circle'></div>
                    <div className='road__cards-line'></div>
                    <div className='road__cards-lines-circle'></div>
                    <div className='road__cards-line'></div>
                    <div className='road__cards-lines-circle'></div>
                    <div className='road__cards-line road__cards-line_little'></div>
                </div>
                <div className='road__cards-box'>
                    <div className='road__card'>
                        <p className='road__card-date'>07.2021</p>
                        <p className='road__card-text'>Start of development</p>
                    </div>
                    <div className='road__card'>
                        <p className='road__card-date'>09.2022</p>
                        <p className='road__card-text'>First test with 100 patients<br/>in an Australian clinic.</p>
                    </div>
                    <div className='road__card'>
                        <p className='road__card-date'>11.2022</p>
                        <p className='road__card-text'>Adding gamification mechanics and a general assessment of the patient's well-being</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Road;
