import Newsletter from '../Newsletter/Newsletter';
import './Doctors.css';

import { motion } from 'framer-motion'
import Banner from './Banner/Banner';
import Data from './Data/Data';
import Recomended from './Recomended/Recomended';
import Demo from './Demo/Demo';
import Reviews from './Reviews/Reviews';


function Doctors() {
    return (

        <div
            className="doctors"
            >
            <Banner />
            <div className="doctors__content">
                <Data />
                <Recomended />
                <Demo />
                <Reviews />
                <Newsletter />
            </div>
        </div>
    );
}

export default Doctors;
