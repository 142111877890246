import Newsletter from '../Newsletter/Newsletter';
import './Ai.css';
import Banner from './Banner/Banner';
import Description from './Description/Description';
import { motion } from 'framer-motion'
import Alghoritm from './Alghoritm/Alghoritm';

function Ai() {
    return (

        <div
            className="ai"
           
        >
            <Banner />
            <div className="ai__content">
                <Description />
                <Alghoritm />
                <Newsletter />
            </div>
        </div>
    );
}

export default Ai;
