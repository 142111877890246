
import './Introduction.css';
import slide_bg from '../../../assets/images/slide_bg.png'
import slide1_screen from '../../../assets/images/slide1_screen.png'
import slide2_screen from '../../../assets/images/slide2_screen.png'
import slide3_screen from '../../../assets/images/slide3_screen.png'

import slide1MrPill from '../../../assets/images/slide1_mrpill.png'
import slide1 from '../../../assets/images/slide1.png'
import slide2MrPill from '../../../assets/images/slide2_mrpill.png'
import slide2 from '../../../assets/images/slide2.png'
import slide3MrPill from '../../../assets/images/slide3_mrpill.png'
import slide3 from '../../../assets/images/slide3.png'
import { useEffect, useRef, useState } from 'react';

function Introduction() {

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const listInnerRef = useRef();

    const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
    const [pageValue, setPageValue] = useState(0);


    const [scrollPosition, setScrollPosition] = useState(0);
    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    const [scrollTraking, setScrollTraking] = useState(true);
    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const [slideNumber, setSlideNumber] = useState(0)
    const [slideOpacity, setSlideOpacity] = useState(0)

    useEffect(() => {
        // console.log(prevScrollPosition)
        if (listInnerRef.current && scrollTraking) {
            const scrol = scrollPosition
            setPrevScrollPosition(scrollPosition)
            const { scrollHeight, clientHeight, offsetTop } = listInnerRef.current;

            if (offsetTop <= scrol && scrol <= offsetTop + scrollHeight) {
                let elem_height = (scrollHeight - 760 - (100 * 2)) / 2
                let position = scrol - offsetTop
                console.log(position % elem_height)
                if(position % elem_height > 200){
                    let slide_number_inview = Math.trunc(position / elem_height)
                    let slide_opacity = Number((Number((position / elem_height).toFixed(1)) - slide_number_inview).toFixed(1))
                    console.log({ position, elem_height, slide_number_inview, slide_opacity })
                    setSlideNumber(slide_number_inview)
                    setSlideOpacity(slide_opacity)
                }
               
            } else if (scrol < offsetTop) {
                setSlideNumber(0)
                setSlideOpacity(0)
            }
            else {
                setSlideNumber(2)
                setSlideOpacity(0)
            }

        }
    }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue]);


    // function handleeScroll(e) {
    //     // console.log(e.target.scrollTop)
    //     console.log(e.target.scrollTop)
    //     setScrollPosition(e.target.scrollTop);
    // }

    return (
        <div className="introduction">
            <p className="introduction__heading">
                Hello there,<br />ZAM is here to help you improve your health and stay on track with your medication schedule
            </p>
            {isSafari ? <></> :
                <div className='introduction__slides-on-pc-box' ref={listInnerRef} id="listInner" >

                    <div className="introduction__slides-on-pc">

                        <div className='introduction__slide introduction__slide_pc'>
                            <div className='introduction__slide-text-box'>
                                <img className='introduction__slide-text-img' src={slide1MrPill} alt="" ></img>
                                <div className='introduction__slide-text-container'>
                                    <p className='introduction__slide-text'>ZAM helps you track your medication schedule and sends reminders for every intake</p>
                                    <svg
                                        width={20}
                                        height={15}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className='introduction__slide-text-corner'
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                            fill="#F3F7F3"
                                        />
                                    </svg>
                                </div>
                            </div>

                        </div>
                        <div className='introduction__slide introduction__slide_pc'>
                            <div className='introduction__slide-text-box'>
                                <img className='introduction__slide-text-img' src={slide2MrPill} alt="" ></img>
                                <div className='introduction__slide-text-container'>
                                    <p className='introduction__slide-text'>Daily questionnaires can help you understand how effective your medication intake is and track your progress</p>
                                    <svg
                                        width={20}
                                        height={15}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className='introduction__slide-text-corner'
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                            fill="#F3F7F3"
                                        />
                                    </svg>
                                </div>
                            </div>

                        </div>
                        <div className='introduction__slide introduction__slide_pc'>
                            <div className='introduction__slide-text-box'>
                                <img className='introduction__slide-text-img' src={slide3MrPill} alt="" ></img>
                                <div className='introduction__slide-text-container'>
                                    <p className='introduction__slide-text'>Track your progress and experience the benefits of your medication schedule firsthand</p>
                                    <svg
                                        width={20}
                                        height={15}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className='introduction__slide-text-corner'
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                            fill="#F3F7F3"
                                        />
                                    </svg>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='introduction__slide-img-box'>


                        <img src={slide1_screen} alt='' className='introduction__slide-img-screen'></img>
                        <img src={slide2_screen} alt='' className='introduction__slide-img-screen'
                            style={{
                                opacity: `${slideNumber >= 0 ? slideNumber >= 1 ? '1' : slideOpacity : '0'}`
                            }}
                        ></img>
                        <img src={slide3_screen} alt='' className='introduction__slide-img-screen'
                            style={{
                                opacity: `${slideNumber >= 1 ? slideNumber >= 2 ? '1' : slideOpacity : '0'}`
                            }}
                        ></img>
                        <img src={slide_bg} alt='' className='introduction__slide-img'></img>
                    </div>

                </div>
            }


            <div className={`introduction__slides ${isSafari ? '' : 'introduction__slides_mobile'}`}>
                <div className='introduction__slide'>
                    <div className='introduction__slide-text-box'>
                        <img className='introduction__slide-text-img' src={slide1MrPill} alt="" ></img>
                        <div className='introduction__slide-text-container'>
                            <p className='introduction__slide-text'>ZAM helps you track your medication schedule and sends reminders for every intake</p>
                            <svg
                                width={20}
                                height={15}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className='introduction__slide-text-corner'
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                    fill="#F3F7F3"
                                />
                            </svg>
                        </div>
                    </div>
                    <img src={slide1} alt='' className='introduction__slide-img'></img>
                </div>
                <div className='introduction__slide'>
                    <div className='introduction__slide-text-box'>
                        <img className='introduction__slide-text-img' src={slide2MrPill} alt="" ></img>
                        <div className='introduction__slide-text-container'>
                            <p className='introduction__slide-text'>Daily questionnaires can help you understand how effective your medication intake is and track your progress.</p>
                            <svg
                                width={20}
                                height={15}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className='introduction__slide-text-corner'
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                    fill="#F3F7F3"
                                />
                            </svg>
                        </div>
                    </div>
                    <img src={slide2} alt='' className='introduction__slide-img'></img>
                </div>
                <div className='introduction__slide'>
                    <div className='introduction__slide-text-box'>
                        <img className='introduction__slide-text-img' src={slide3MrPill} alt="" ></img>
                        <div className='introduction__slide-text-container'>
                            <p className='introduction__slide-text'>Track your progress and experience the benefits of your medication schedule firsthand.</p>
                            <svg
                                width={20}
                                height={15}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className='introduction__slide-text-corner'
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 14.172v.328h6c7.732 0 14-6.268 14-14H5c0 2.738 0 4.107-.19 5.252A13.99 13.99 0 010 14.172z"
                                    fill="#F3F7F3"
                                />
                            </svg>
                        </div>
                    </div>
                    <img src={slide3} alt='' className='introduction__slide-img'></img>
                </div>
            </div>
        </div>
    );
}

export default Introduction;
