
import './Alghoritm.css';

import aiPreview from '../../../assets/images/aiPreview.png'


function Alghoritm() {
    return (

        <div className='alghoritm'>
            <p className='alghoritm__title'>Our algorithm queries the user's condition and compares it with previously collected databases of medical information on patients with similar experiences, in order to provide a more personalized answer</p>
            <p className='alghoritm__text'>Our algorithm will be able to suggest both over-the-counter drugs and identify any red flags in a patient's condition, referring them immediately to a doctor if necessary</p>
            <img src={aiPreview} alt="" className='alghoritm__image'></img>
        </div>
    );
}

export default Alghoritm;
