
import Newsletter from '../Newsletter/Newsletter';

import './AboutUs.css';


import { motion } from 'framer-motion'
import Banner from './Banner/Banner';
import Meet from './Meet/Meet';
import Road from './Road/Road';
import Team from './Team/Team';

function AboutUs() {
    return (
        <div
            className="about-us"
         

        >

            <Banner />
            <div className="about-us__content">
                <Meet />
                <Road />
               
                {/* <Team /> */}

                <Newsletter />
            </div>


        </div>

    );
}

export default AboutUs;
