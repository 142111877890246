import './MenuPopup.css';
import React from "react";
import { NavLink } from 'react-router-dom';






function MenuPopup({ setCurrentUser, isOpen, setOpen, currentUser, language, setLanguage, setTheme, theme, cources }) {

  function handleClose() {
    setOpen(prevState => ({
      ...prevState,
      menuPopup: false,
    }))
  }

  return (
    <>

      <div className={`menu-popup-top ${isOpen ? 'menu-popup_active' : ''}`}>
        <div className={`menu-popup__container ${isOpen ? 'menu-popup__container_active' : ''}`}>
          <button className='menu-popup__close' type='button' onClick={handleClose}>
            <svg
              width={32}
              height={32}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className='menu-popup__close-icon'
            >
              <path
                d="M5.673 3.429A2.246 2.246 0 004.086 7.26l8.515 8.515-8.515 8.515a2.244 2.244 0 103.175 3.175l8.515-8.516 8.515 8.516a2.246 2.246 0 003.174-3.175l-8.515-8.515 8.515-8.515a2.245 2.245 0 10-3.174-3.174l-8.515 8.515L7.26 4.087a2.245 2.245 0 00-1.587-.658z"
                fill="#fff"
                fillOpacity={0.92}
                className='menu-popup__close-icon-fill'
              />
            </svg>
          </button>
          <NavLink end to={'/'} onClick={handleClose}
            className={({ isActive }) =>
              "menu-popup__nav-link" + (isActive ? " menu-popup__nav-link_active" : "")
            }
          >
            Main
          </NavLink>
          <NavLink end to={'/about-us'} onClick={handleClose}
            className={({ isActive }) =>
              "menu-popup__nav-link" + (isActive ? " menu-popup__nav-link_active" : "")
            }
          >
            About us
          </NavLink>
          <NavLink end to={'/ai'} onClick={handleClose}
            className={({ isActive }) =>
              "menu-popup__nav-link" + (isActive ? " menu-popup__nav-link_active" : "")
            }
          >
            AI in service of patients
          </NavLink>
          <NavLink end to={'/doctors'} onClick={handleClose}
            className={({ isActive }) =>
              "menu-popup__nav-link" + (isActive ? " menu-popup__nav-link_active" : "")
            }
          >
            Doctors dashboard
          </NavLink>

        </div>
        <div className={`menu-popup__background ${isOpen ? 'menu-popup__background_active' : ''}`} onClick={() => {
          handleClose()

        }}>

        </div>


      </div>
    </>

  );
}

export default MenuPopup;
