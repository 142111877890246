
import './Demo.css';
import img from '../../../assets/images/demo.png'
import img_mob from '../../../assets/images/demo_mob.png'
import { DEMO_DOCTORS_URL } from '../../../assets/utils/constants';

function Demo() {
    return (

        <div className='demo'>
            <p className='demo__title'>Doctors receive full statistics on their patients' medication intake and their "health score”</p>
            <div className='demo__img-box'>
                <img src={img} alt='' className='demo__img demo__img_desktop'></img>
                <img src={img_mob} alt='' className='demo__img demo__img_mobile'></img>
                <a  className='demo__img-link'  href={DEMO_DOCTORS_URL}  target="_blank" rel="noreferrer">View demo</a>
            </div>
        </div>
    );
}

export default Demo;
