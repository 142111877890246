
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Ai from '../Ai/Ai';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Main from '../Main/Main';
import MenuPopup from '../MenuPopup/MenuPopup';
import './App.css';




import Doctors from '../Doctors/Doctors';
import NotFond from '../NotFond/NotFond';
import AboutUs from '../AboutUs/AboutUs';

function App() {

  const [popups, setPopups] = useState({
    menuPopup: false,
  })

  function handleMenuPopupOpen() {
    setPopups(prevState => ({
      ...prevState,
      menuPopup: true,
    }))
  }
  return (
    <div className="app">
      <MenuPopup isOpen={popups.menuPopup} setOpen={setPopups} />
      <Header handleMenuPopupOpen={handleMenuPopupOpen} />
      <Routes >
        <Route exact path='/' element={<Main />} />
        <Route path='/ai' element={<Ai />} />
        <Route path='/doctors' element={<Doctors />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='*' element={<NotFond />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
