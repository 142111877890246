
import './Banner.css';
import img from '../../../assets/images/ai-banner.png'

function Banner() {
    return (
        <div className="ai-banner">
           <p className='ai-banner__title'>Pillbox<br/>powered by AI</p>
            <img src={img} alt='' className='ai-banner__img'></img>
        </div>
    );
}

export default Banner;
