
import './Banner.css';
import img from '../../../assets/images/doctors-banner.png'
import { DEMO_DOCTORS_URL } from '../../../assets/utils/constants';

function Banner() {
    return (
        <div className="doctors-banner">
            <div className="doctors-banner__texts">
                <p className='doctors-banner__title'>Patient's health on the palm of your hand</p>
                <a className='doctors-banner__link' href={DEMO_DOCTORS_URL}  target="_blank" rel="noreferrer">
                    <p className='doctors-banner__link-text'>View demo</p>
                    <svg className='doctors-banner__link-arrow' width="45" height="8" viewBox="0 0 45 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM44.3536 4.35355C44.5488 4.15829 44.5488 3.84171 44.3536 3.64645L41.1716 0.464466C40.9763 0.269204 40.6597 0.269204 40.4645 0.464466C40.2692 0.659728 40.2692 0.976311 40.4645 1.17157L43.2929 4L40.4645 6.82843C40.2692 7.02369 40.2692 7.34027 40.4645 7.53553C40.6597 7.7308 40.9763 7.7308 41.1716 7.53553L44.3536 4.35355ZM1 4.5H44V3.5H1V4.5Z" fill="white" />
                    </svg>

                </a>
            </div>
            <img src={img} alt='' className='doctors-banner__img'></img>
        </div>
    );
}

export default Banner;
