
import Newsletter from '../Newsletter/Newsletter';
import Banner from './Banner/Banner';
import InSync from './InSync/InSync';
import Introduction from './Introduction/Introduction';
import './Main.css';
import TakeCare from './TakeCare/TakeCare';

import { motion } from 'framer-motion'

function Main() {
    return (
        <div
            className="main"
            

        >

            <Banner />
            <div className="main__content">
                <Introduction />
                <TakeCare />
                <InSync />
                <Newsletter />
            </div>


        </div>

    );
}

export default Main;
