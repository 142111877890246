
import { MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl
  }


  newsletterSubscribe({ email }) {
    return fetch(`${MAIN_URL}/landing/subscribe`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "email": email,
      })
    }).then(this._checkResponse)
  };

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    else {
      var statusCode = res.status
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.message,
          detail: res.detail
        })
      })
    }
    ;
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    }
    else {
      var statusCode = res.status
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === 'Signature has expired') {
          console.log('ss')
          return mainApi.refreshJWT()
            .then((res) => {
              return func(params)
            })
            .catch((err) => {
              return Promise.reject(err)
            })


        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.message,
            detail: res.detail
          })
        }

      })
    }
  }

}

const mainApi = new MainApi({
  baseUrl: MAIN_URL
  // baseUrl: 'http://localhost:3003'
});
export default mainApi
