
import { Link } from 'react-router-dom';
import mainImg from '../../../assets/images/in_sync.png'
import stethoscope from '../../../assets/images/stethoscope.png'
import './InSync.css';

function InSync() {
    return (
        <div className="in-sync">
            <p className='in-sync__title'>Be in sync<br />with your doctor</p>
            <p className='in-sync__text'>Our special dashboard for doctors allows for constant tracking and shows the effectiveness of a medication schedule.</p>
            <Link className='in-sync__link' to={'/doctors'}>Learn more</Link>
            <div className='in-sync__img-box'>
                <img className='in-sync__main-img' src={mainImg} alt=''></img>
                <img className='in-sync__stethoscope-img' src={stethoscope} alt=''></img>
            </div>
        </div>
    );
}

export default InSync;
