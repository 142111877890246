
import './TakeCare.css';
import cardIcon1 from '../../../assets/images/calendar.png'
import cardIcon2 from '../../../assets/images/genders.png'
import cardIcon3 from '../../../assets/images/ruler.png'
import cardIcon4 from '../../../assets/images/docs.png'

function TakeCare() {
    return (
        <div className="take-care">
            <p className="take-care__title">Take care of yourself with ease</p>
            <div className="take-care__row take-care__row_1">
                <div className="take-care__card">
                    <img src={cardIcon1} alt='' className='take-care__card-icon'></img>
                    <p className='take-care__card-title'>Simple medicine tracking</p>
                    <p className='take-care__card-text'>Track your medication intakes and never forget to take your medicine. Additionally, receive reminders when you are about to finish your medication course</p>
                </div>
                <div className="take-care__card">
                    <img src={cardIcon2} alt='' className='take-care__card-icon'></img>
                    <p className='take-care__card-title'>Get tailored recommendations</p>
                    <p className='take-care__card-text'>You will receive tailored recommendations based on your medication intake history and effectiveness</p>
                </div>
            </div>
            <div className="take-care__row take-care__row_2">
                <div className="take-care__card">
                    <img src={cardIcon3} alt='' className='take-care__card-icon'></img>
                    <p className='take-care__card-title'>Track effectiveness</p>
                    <p className='take-care__card-text'>By completing daily surveys, you will be able to track the effectiveness of a particular medication</p>
                </div>
                <div className="take-care__card">
                    <img src={cardIcon4} alt='' className='take-care__card-icon'></img>
                    <p className='take-care__card-title'>Help your doctor</p>
                    <p className='take-care__card-text'>By using the app, your doctor will have a more complete picture of your health and be better able to provide treatment</p>
                </div>
                <a className="take-care__link" href='https://pwa.zamapp.tech' target="_blank" rel="noreferrer">
                    <p className="take-care__link-title">Get started now!</p>
                    <svg
                        width={121}
                        height={9}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="take-care__link-arrow"
                    >
                        <path
                            d="M120.354 4.854a.5.5 0 000-.708L117.172.964a.501.501 0 00-.708.708l2.829 2.828-2.829 2.828a.5.5 0 00.708.708l3.182-3.182zM0 5h120V4H0v1z"
                            fill="#fff"
                        />
                    </svg>
                </a>
            </div>
        </div>
    );
}

export default TakeCare;
